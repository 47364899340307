import React from "react";

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        © 2022 <b>MSBTE, Bandra, Mumbai</b>
      </footer>
    </div>
  );
};

export default Footer;
